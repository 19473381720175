import style from "./burger-menu-nav-item.module.scss"
import Image from 'next/image'
import { HeaderNavigation } from "../../header.state";
import { useState } from "react";
import { Themes } from '@layouts/main-layout/themes.enum';
import ShouldRender from '@components/shared/boundaries/should-render';
import AgilieLink from '@components/shared/links/agilie-link';

interface IBurgerMenuNavItemProps {
  item: HeaderNavigation;
  onMenuClicked: () => void;
  theme?: Themes;
}

// todo: add types
const ExpandableNavigationItem = ({active, item, theme}: any) => {
  const iconClasses: string = active ? style.active : '';
  const iconSrc: string = '/assets/icons/icn-arrow.svg';
  const iconAlt: string = `Expand ${item.name}`;

  return (
    <>
      <span>{item.name}</span>
      <div className={`${style.link__icon} ${iconClasses} ${style[theme]}`}>
        <Image src={iconSrc} alt={iconAlt} width={20} height={20}/>
      </div>
    </>
  )
}

export default function BurgerMenuNavItem({ item, onMenuClicked, theme = Themes.Default }: IBurgerMenuNavItemProps) {
  theme ||= Themes.Default;
  const [active, setActive] = useState<boolean>(false);
  const isExpandable: boolean = !!item.burgerPreview;

  const toggleActive = (): void => {
    setActive((prev) => !prev);
  }

  return (
    <div className={`${style.wrapper} ${style[theme]}`}>
      <div onClick={() => isExpandable && toggleActive()} className={style.link}>
        {/*<ExpandableNavigationItem/>*/}
        {/*{isExpandable ? }*/}


        <ShouldRender
          if={isExpandable}
          else={<AgilieLink href={item.link} label={item.name} onClick={onMenuClicked} classNames={style.link__item}/>}
        >
          <ExpandableNavigationItem active={active} item={item} theme={theme}/>
        </ShouldRender>
      </div>
      <ShouldRender if={active}>
        {(item.burgerPreview?.(theme, onMenuClicked) ?? <></>)}
      </ShouldRender>
    </div>
  )
}

