import style from "./burger-menu.module.scss"
import { HeaderNavigation, HeaderState } from "../header.state";
import BurgerMenuNavItem from "./burger-menu-nav-item/burger-menu-nav-item";
import { useState } from "react";
import dynamic from "next/dynamic";
import { Themes } from '@layouts/main-layout/themes.enum';

const StartProjectModal = dynamic(() => import('@components/shared/interactions/start-project/start-project.modal'));

interface IBurgerMenuProps {
  isOpened: boolean;
  onMenuClicked: () => void;
  theme?: Themes;
}

export default function BurgerMenu({ isOpened, onMenuClicked, theme }: IBurgerMenuProps) {
  const [isStartProjectModalOpened, setStartProjectModalOpened] = useState(false);

  const openStartProjectWindow = function () {
    setStartProjectModalOpened(true);
    onMenuClicked();
  }
  const {
    navigation
  } = HeaderState;

  if (!theme) {
    theme = Themes.Default;
  }
  
  return (

    <>
      {/*Burger Menu for Mobile version*/}
      <div className={`${style.menu} ${style[theme]} ${isOpened ? `${style.menu_active}` : null}`}>
        <div className={`${style.menu__block}`}>
          <div className={`${style.menu__nav}`}>
            {navigation.map((item: HeaderNavigation, index: number) => {
              return (
                 <BurgerMenuNavItem theme={theme} onMenuClicked={onMenuClicked} key={index} item={item}/>
              )
            })}
          </div>
          <div className={`${style.button__block}`}>
            <button onClick={openStartProjectWindow} className={`${style.button}`}>
              Start a project
            </button>
          </div>
        </div>
      </div>
      {/* ====== Modals ====== */}
      <StartProjectModal
        isOpen={isStartProjectModalOpened}
        onClose={() => setStartProjectModalOpened(false)}
      />
    </>
  );
}

