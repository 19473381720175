import Link from 'next/link';
import { FC } from 'react';

type Props = {
  href: string;
  label: string;
  onClick?: () => void;
  classNames?: string;
}

// note: prefixed in order to avoid conflicts between next/link and this component
const AgilieLink: FC<Props> = ({href, label, onClick, classNames}) => {
  return <Link href={href}>
    <a onClick={onClick} className={classNames}>{label}</a>
  </Link>
}

export default AgilieLink;
