type ShouldRenderProps = {
  children: JSX.Element; // todo: add appropriate types
  if: unknown;
  else?: JSX.Element;
}

/**
 * Works as *ngIfElse.
 * Determines if condition is truthy value and either renders children or fallback/empty string
 * */
const ShouldRender = ({ if: condition, else: fallback, children }: ShouldRenderProps) => {
  return condition ? children : (fallback ?? '') as unknown as JSX.Element;
}

export default ShouldRender;
